<template>
  <div class='border-t bg-white flex flex-col py-12'>
    <div class='layout-container w-full bg-white flex flex-col-reverse lg:flex-row-reverse lg:gap-x-8 gap-y-8 justify-between px-4 lg:px-0 text-xs lg:text-sm text-gray-700'>
      <div class='flex flex-col justify-between items-start lg:items-end'>
        <div>
          <img :src='eventConfigLogoImageUrl'
             class='h-12 cursor-pointer mb-6 w-auto'
             @click='goToHome'/>
        </div>
        <div
          v-if='hasValidToken' 
          class='text-left uppercase border px-4 py-2 text-center rounded-md hover:shadow-md cursor-pointer'
          @click='logoutApp'>
          logout
        </div>
      </div>
      <div class='text-sm'>
        <button
          class='px-8 py-2 text-yellow-900 font-semibold rounded-md'
          style='background-color: #f6e113; color: #4b383f;'
          @click='kakaoChannelChat'>
          <chat-alt-2-icon class='inline-block mr-2' />카카오톡 문의하기
        </button>
        <div class='mt-2 flex flex-col lg:flex-row justify-start text-left w-full items-start'>
          © Korean Industrial Hygiene Association
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { ChatAlt2Icon } from '@vue-hero-icons/outline'

export default {
  name: 'KihaLayoutFooter',
  components: {
    ChatAlt2Icon
  },
  computed: {
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapGetters('events', [
      'eventConfigLogoImageUrl',
      'eventKakaoSupportUrl',
    ]),
  },
  methods: {
    ...mapActions('users', [
      'logout',
      'checkTokenStatus',
    ]),
    logoutApp () {
      this.logout().then(() => {
        this.$router.push({name: 'Login'})
      })
    },
    kakaoChannelChat () {
      window.open(this.eventKakaoSupportUrl, '_blank', 'width=350,height=550');
    },
    goToHome () {
      this.$router.push({name: 'Home'}).catch(() => {})
    },
  },
  created () {
    this.checkTokenStatus().then(() => {
      if (!this.hasValidToken && this.$router.currentRoute.name !== 'ReceiptDownloadFile' && this.$router.currentRoute.name !== 'CertificateDownload') {
        this.$router.replace({name: 'Login'}).catch(() => {})
      }
    })
  }
}
</script>
